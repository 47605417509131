<template>
    <div class="app-massagist-personal-page-photos">
        <AppPageLoadingAnimation v-if="massagistsPhotos.loading" />
        <transition name="scale">
            <AppUploadFileForm
                @close="popupMenuForFileUploading.closeMenu"
                @upload="popupMenuForFileUploading.getFile($event)"
                @action="popupMenuForFileUploading.uploadFile"
                :uploading="popupMenuForFileUploading.uploading"
                :disabled="!popupMenuForFileUploading.fileToUpload"
                v-if="popupMenuForFileUploading.isMenuOpened" />
        </transition>
        <div class="photo" v-if="isAnyPhotos">
            <div class="image">
                <img :src="massagistsPhotos.path + massagistsPhotos.massagistID + '/' + massagistsPhotos.mainPhoto.link" alt="">
            </div>
            <div class="actions">
                <AppLittleWarningIcon class="icon" />
                <span>Главное фото</span>
                <AppDeleteIcon class="icon" @action="massagistsPhotos.deletePhoto(massagistsPhotos.mainPhoto.id)" />
                <span>Удалить</span>
            </div>
            <div class="comment">Фото будет доступно посетителям только после проверки модератором</div>
        </div>
        <div class="photo" v-for="photo, index in massagistsPhotos.photos" :key="index">
            <div class="image">
                <img :src="massagistsPhotos.path + massagistsPhotos.massagistID + '/' + photo.link" alt="">
            </div>
            <div class="actions">
                <AppLittleWarningIcon class="icon" @action="massagistsPhotos.setPhotoAsMain(photo.id)" />
                <span>Сделать главной</span>
                <AppDeleteIcon class="icon" @action="massagistsPhotos.deletePhoto(photo.id)"/>
                <span>Удалить</span>
            </div>
            <div class="comment">Фото будет доступно посетителям только после проверки модератором</div>
        </div>
        <div class="no-photos-massage" v-if="!isAnyPhotos">Вы не добавили ни одной фотографии</div>
        <div class="add-photos" @click="popupMenuForFileUploading.openMenu">
            <span class="icon">+</span>
            <span class="text">Добавить фото</span>
        </div>
    </div>
</template>

<script>
import AppLittleWarningIcon from "@/components/icons/app-little-warning-icon.vue";
import AppDeleteIcon from "@/components/icons/app-delete-icon.vue";
import AppUploadFileForm from "@/components/forms/app-upload-file-form.vue";
import AppPageLoadingAnimation from "@/components/animations/app-page-loading-animation.vue";
import massagistApi from "@/api/massagist.js";
import domain from "@/api/domain.js";
import { computed, reactive, onMounted } from 'vue';
import { useRoute } from "vue-router";
import { useStore } from "vuex";

    export default {
        components: {
            AppLittleWarningIcon,
            AppDeleteIcon,
            AppUploadFileForm,
            AppPageLoadingAnimation
        },
        setup(){
            const route = useRoute();
            const store = useStore();

            const popupMenuForFileUploading = reactive({
                isMenuOpened: false,
                fileToUpload: null,
                uploading: false,
                openMenu: () => popupMenuForFileUploading.isMenuOpened = true,
                closeMenu: () => {
                    popupMenuForFileUploading.isMenuOpened = false;
                    popupMenuForFileUploading.fileToUpload = null;
                },
                getFile: (file) => {
                    popupMenuForFileUploading.fileToUpload = file;
                    
                },
                uploadFile: async () => {
                    popupMenuForFileUploading.uploading = true;
                    let formData = new FormData();
                    formData.append('file', popupMenuForFileUploading.fileToUpload);                   
               
                    await massagistApi.massagistUploadPhoto(formData, route.params.id).catch(err => console.log(err))
                    await massagistsPhotos.getAllMassagistPhotos(route.params.id);
                    popupMenuForFileUploading.uploading = false;
                    popupMenuForFileUploading.closeMenu();
                }
            });

            const massagistsPhotos = reactive({
                path: domain.massagistPhotosPath,
                loading: true,
                massagistID: route.params.id,
                photos: [],
                mainPhoto: null,
                getAllMassagistPhotos: async (id) => {
                    massagistsPhotos.photos = [];
                    const response = await massagistApi.getAllMassagistPhotos({ massagist_id: id }).then(response => response).catch(err => console.log(err));
                    if(response.data.length < 1){
                        massagistsPhotos.photos = [];
                        massagistsPhotos.mainPhoto = null;
                        store.dispatch('dropUserMainPhoto');
                    }                 
                    response.data.forEach(photo => {
                        if(photo.main_photo){
                            massagistsPhotos.mainPhoto = photo;
                            store.dispatch('setUserMainPhoto', photo);
                        } else{
                            massagistsPhotos.photos.push(photo)
                        }
                    })
                    massagistsPhotos.loading = false;
                },
                setPhotoAsMain: async (id) => {
                    massagistsPhotos.loading = true;
                    await massagistApi.setMassagistPhotoAsMain({ massagist_id: massagistsPhotos.massagistID, photo_id: id, token: store.state.user.token }).catch(err => console.log(err));
                    await massagistsPhotos.getAllMassagistPhotos(massagistsPhotos.massagistID).catch(err => console.log(err));
                },
                deletePhoto: async (id) => {
                    massagistsPhotos.loading = true;
                    await massagistApi.deleteMassagistPhoto({ massagist_id: massagistsPhotos.massagistID, photo_id: id, token: store.state.user.token }).catch(err => console.log(err));
                    await massagistsPhotos.getAllMassagistPhotos(massagistsPhotos.massagistID).catch(err => console.log(err));
                }
            });

            const isAnyPhotos = computed(() => {
                if(massagistsPhotos.mainPhoto) {
                    return true
                } else {
                    return false
                }
            })

            onMounted(massagistsPhotos.getAllMassagistPhotos(route.params.id))


            return {
                popupMenuForFileUploading,
                massagistsPhotos,
                isAnyPhotos
            }
        }
    }
</script>

<style lang="scss" scoped>
    .scale-enter-active{
        transition: 0.2s ease;
    }
    .scale-enter-from {
        transform: translate(-50%, -50%) scale(0);
    }
    .scale-enter-to{
        transform: translate(-50%, -50%) scale(1);
    }
    .scale-leave-active{
        transition: 0.2s ease;
    }
    .scale-leave-from {
        transform: translate(-50%, -50%) scale(1);
    }
    .scale-leave-to{
        transform: translate(-50%, -50%) scale(0);
    }
    .app-massagist-personal-page-photos{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        position: relative;
        padding-bottom: 7%;
   
        .add-photos{
            position: fixed;
            right: 5px;
            bottom: 15%;
            z-index: 1000;
            padding: 0.5em 2em;
            border-radius: 1em;
            background-color: $accent;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .icon{
                font-size: 2em;
                color: $accent-dark;
                font-weight: 700;
                margin-right: 0.5em;
            }
            .text{
                color: $white;
            }
        }
        .photo{
            width: 300px;
            height: 400px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 0.5em;
            border: transparent;
            margin: 1em 0;
            box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
            .image{
                max-width: 100%;
                height: 90%;
                background-color: $white;
                overflow: hidden;
                border-top-left-radius: 0.5em;
                border-top-right-radius: 0.5em;
                position: relative;                
                img{
                    max-width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .actions{
                width: 100%;
                height: 10%;
                background-color: $accent;
                border-bottom-left-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon{
                    cursor: pointer;
                    margin-right: 0.5em;
                }
                span{
                    font-size: 0.7em;
                    font-weight: 700;
                    margin-right: 0.5em;
                }
            }
            .comment{
                color: $error;
                font-size: 0.8em;
                font-weight: 700;
                text-align: center;
                padding: 0.2em;
            }
        }
    }

    @media screen and (min-width: 1024px){
        .app-massagist-personal-page-photos{
            min-height: 500px;       
            .add-photos{
                position: absolute;
                right: 0;
                bottom: 2%;
                z-index: 1000;
                padding: 0.5em 2em;
                border-radius: 1em;
                background-color: $accent;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .icon{
                    font-size: 2em;
                    color: $accent-dark;
                    font-weight: 700;
                    margin-right: 0.5em;
                }
                .text{
                    color: $white;
                }
            }
        }
    }
</style>